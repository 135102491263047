<template>
<div id="app" class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
  <nav id="site-navigation" class="has-branding main-navigation grid-container nav-align-right sub-menu-right" aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
    <div class="inside-navigation grid-container">
      <div class="navigation-branding">
        <div class="site-logo">
          <a href="https://gbapks.com.ru/" title="GBWhatsApp" rel="home">
            <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp" title="GBWhatsApp">
          </a>
        </div>
        <p class="main-title" itemprop="headline">
          <a href="https://gbapks.com.ru/" rel="home">
            GBWhatsApp
          </a>
        </p>
      </div>

      <div id="primary-menu" class="main-nav">
        <ul id="menu-primary-menu" class="menu sf-menu">
          <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
            <a href="https://gbapks.com.ru/" aria-current="page">Home</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a @click="gotodownload()">Download</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbapks.com.ru/blogs/">Blogs</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div id="page" class="site grid-container container hfeed">
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="maincontent" class="site-main">
          <section id="section-1" class="section-content">
            <h1 class="section-1-blog-head">
              Backup GB WhatsApp Chat to Google Drive/PC (Restore Backup)
            </h1>

            <p class="writter-content">
              GB WhatsApp is a popular modified version of the official WhatsApp app, offering additional customization options and features. However, because it is an unofficial app, GB WhatsApp cannot access Google Drive directly, which limits its backup options. In this guide, we'll introduce the methods to back up GB WhatsApp chats, transfer them to a new phone, and even transfer them back to the official WhatsApp if needed.
            </p>

            <div class="lazy-picture-container writer-banner">
              <picture><img width="auto" height="auto" alt="gbwhatsapp google drive" src="../assets/blog-1.webp"></picture>
            </div>

            <h2 class="intro-title blog">
              Why GB WhatsApp Can't Access Google Drive
            </h2>

            <p class="writter-content">
              As an unofficial app developed by third-party developers, <a href="https://gbapks.com.ru/" class="jump-url">GB WhatsApp</a> does not have authorization to access <a href="https://workspace.google.com/intl/en_sg/products/drive/" ref="nofollow" target="_blank" class="jump-url">Google Drive</a>. Unlike the official WhatsApp, which allows seamless backup and restoration of chat history via Google Drive, GB WhatsApp lacks this integration. Users of GB WhatsApp must therefore rely on alternative backup methods, such as local backups, or use manual workarounds to save chat data.
            </p>

            <h2 class="intro-title blog">
              Steps to Backup GB WhatsApp Chat to Google Drive
            </h2>

            <p class="writter-content">
              Although GB WhatsApp cannot directly sync with Google Drive, there are alternative ways to create backups that you can upload to Google Drive manually:
            </p>

            <h3>
              Step 1: Create a Local Backup of GB WhatsApp Chats
            </h3>
            <ul>
              <li>- Open <strong>GB WhatsApp</strong> and go to <strong>Settings</strong>.</li>
              <li>- Navigate to <strong>Chats > Chat backup</strong>.</li>
              <li>- Tap on <strong>Backup</strong> to save your chat history to your device's local storage.</li>
            </ul>
            <h3>
              Step 2: Locate the Backup File
            </h3>
            <ul>
              <li>- Use a file manager app on your device to go to the GB WhatsApp folder, usually located in <strong>Internal Storage > GBWhatsApp > Databases</strong>.</li>
              <li>- Here, you'll find the backup files (usually named in the format <code>msgstore.db.crypt</code>).</li>
            </ul>
            <h3>
              Step 3: Upload the Backup File to Google Drive
            </h3>
            <ul>
              <li>- Open the <strong>Google Drive</strong> app on your phone, log into your Google account, and tap on the <strong>plus (+)</strong> icon.</li>
              <li>- Select <strong>Upload</strong> and navigate to the <strong>Databases</strong> folder in GB WhatsApp.</li>
              <li>- Upload the latest backup file. This file can now be accessed from any device with Google Drive.</li>
            </ul>

            <p class="writter-content">
              With this method, you can keep a backup of GB WhatsApp on Google Drive, even though it isn't integrated with the app itself.
            </p>

            <h2 class="intro-title blog">
              How to Transfer GB WhatsApp Backup to a New Phone
            </h2>

            <p class="writter-content">
              To transfer your GB WhatsApp chats to a new phone, follow these steps:
            </p>
            <h3>
              Step 1: Create and Locate a Backup on Your Old Phone
            </h3>
            <ul>
              <li>- On your old device, back up your chats via <strong>Settings > Chats > Chat backup</strong> in GB WhatsApp.</li>
              <li>- Locate the backup file in <strong>Internal Storage > GBWhatsApp > Databases</strong>.</li>
            </ul>
            <h3>
              Step 2: Transfer the Backup File to Your New Phone
            </h3>
            <ul>
              <li>- Use a file transfer method (such as USB cable, Bluetooth, or cloud storage) to copy the <strong>Databases</strong> folder to your new phone's internal storage.</li>
              <li>- Make sure the backup file is placed in the <strong>GBWhatsApp/Databases</strong> folder on the new phone.</li>
            </ul>
            <h3>
              Step 3: Install GB WhatsApp APK on the New Phone
            </h3>
            <ul>
              <li>- Download and install the latest version of GB WhatsApp on your new device.</li>
              <li>- Open GB WhatsApp and verify your phone number.</li>
              <li>- When prompted, tap on <strong>Restore</strong> to load the backup from your new phone's storage.</li>
            </ul>
            <p>
              After these steps, your chat history should be restored on your new device.
            </p>

            <h2 class="intro-title blog">
              How to Transfer GB WhatsApp Backup to the Official WhatsApp
            </h2>

            <p class="writter-content">
              If you'd like to switch back to the official WhatsApp while preserving your GB WhatsApp chat history, follow these steps:
            </p>
            <h3>
              Step 1: Create a Backup on GB WhatsApp
            </h3>
            <ul>
              <li>- In GB WhatsApp, go to <strong>Settings > Chats > Chat</strong> backup and create a backup.</li>
              <li>- Locate the backup file in <strong>GBWhatsApp/Databases</strong>.</li>
            </ul>
            <h3>
              Step 2: Rename and Move the Backup File
            </h3>
            <ul>
              <li>- In the <strong>Databases</strong> folder, find the latest backup file (e.g., <code>msgstore.db.crypt</code>).</li>
              <li>- Rename the file to match WhatsApp's backup format, typically <code>msgstore.db.crypt14</code>.</li>
              <li>- Move the renamed file to the <strong>WhatsApp/Databases</strong> folder on your phone.</li>
            </ul>
            <h3>
              Step 3: Uninstall GB WhatsApp and Install Official WhatsApp
            </h3>
            <ul>
              <li>- Uninstall GB WhatsApp from your device.</li>
              <li>- Download and install the official WhatsApp app from the Google Play Store.</li>
            </ul>
            <h3>
              Step 4: Restore the Backup on Official WhatsApp
            </h3>
            <ul>
              <li>- Open WhatsApp and verify your phone number.</li>
              <li>- When prompted, tap on <strong>Restore</strong> to recover your chat history from the local backup.</li>
            </ul>
            <p class="writter-content">
              With these steps, your GB WhatsApp chats should transfer to the official WhatsApp app.
            </p>

            <h2 class="intro-title blog">
              Conclusion
            </h2>

            <p class="writter-content">
              While GB WhatsApp lacks direct support for Google Drive backups, these methods allow you to securely store and transfer chat histories across devices. Whether you're moving your data to a new phone or back to the official WhatsApp, following these steps will help ensure your conversations are preserved. Just remember to use these methods responsibly, as unofficial apps may have certain security risks compared to official ones.
            </p>

            <div class="is-vertical is-content-justification-center is-layout-flex wp-container-9 wp-block-buttons">
              <div class="wp-block-button">
                <a id="dl" class="wp-block-button__link wp-element-button" target="_blank" rel="dl noopener" @click="gotodownload()">Download Page</a>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  </div>

  <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
    <footer class="site-info">
      <div class="inside-site-info grid-container">
        <div class="footer-bar">
          <a href="https://gbapks.com.ru/privacy/">Privacy Policy</a> |
            <a href="https://gbapks.com.ru/about-us/">About Us</a>
        </div>
        <div class="copyright-bar">
          2025 © All Rights Reserved <strong><a href="https://gbapks.com.ru/">GBWhatsApp</a></strong>
        </div>
      </div>
    </footer>
  </div>

  <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn" @click="gotodownload()">
    Download GBWhatsapp APK
  </div>
</div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/pc.scss';

export default {
  name: 'Home',
  data() {
    return {
      pageName: 'home',
    };
  },
  mounted() {},
  methods: {
    gotodownload() {
      // const params = new URLSearchParams(window.location.href.search)
      let params = window.location.search;
      window.location.href = '/gbwhatsapp-downloadpage' + params;
    },
    jump(url) {
      window.location.href = url;
    },
  },
};
</script>
